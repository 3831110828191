import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { AccordionSidebar } from '@latitude/accordion';
import { Box } from '@latitude/box';
import { Heading4, Heading5 } from '@latitude/heading';
import { ImportantInformation } from '@latitude/important-information';
import { List, ListItem } from '@latitude/list';
import { Metadata } from '@latitude/metadata';
import { FeaturesSlider } from '@latitude/features-slider';
import { Text } from '@latitude/text';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  HEADING,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import { FramedContainer } from '@latitude/framed-container';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import Hero from './_hero';
import DigitalWalletsLinks from './_links';
import faqs from './_faqs';
import imgHowToUse from './images/samsung-pay-phone-all-cards.webp';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const featureSliderItems = [
  {
    icon: 'icon-easy',
    title: 'Simple',
    text:
      'Thanks to Samsung Pay, you can now use your Latitude card in just a tap.'
  },
  {
    icon: 'icon-padlock-confetti',
    title: 'Secure',
    text:
      'With iris scan^, real time notifications, finger print and pass code authorisation, Samsung Pay ensures your compatible Samsung device and identity is protected.<sup>&gt;</sup>'
  },
  {
    icon: 'icon-tick-circle-confetti',
    title: 'Convenient',
    text:
      'Samsung Pay is available now, wherever you see the contactless payment symbol.'
  }
]

const SamsungPay = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why Samsung Pay?',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title={`Samsung Pay & Wallet Setup | Mobile Tap & Pay |  ${TITLE_SUFFIX}`}
          description="Samsung Pay is now available to Latitude customers. See how to setup Samsung Pay on your Samsung devices and make your payments more simple and secure."
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <Lframe payAnyWay />
        <Hero />
        <DigitalWalletsLinks location={location} />
        <Box backgroundColor={COLOR.WHITE}>
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
          />
        </Box>
        <Box.Section
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 8px 0;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 40px 0;
            }
          `}
        >
          <Box padding="0 15px">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Set up and use Samsung Pay
            </Heading4>
            <Box
              css={`
                display: grid;
                grid-gap: ${MARGIN.M24} ${MARGIN.M40};
                h5 {
                  margin-bottom: ${MARGIN.M16};
                  color: ${COLOR.BLACK};
                }
                @media (max-width: ${BREAKPOINT.SM}) {
                  h5 {
                    font-size: ${HEADING['h6'].FONT_SIZE.NORMAL};
                    line-height: ${HEADING['h6'].LINE_HEIGHT.NORMAL};
                  }
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  grid-template-columns: repeat(3, 1fr);
                  grid-template-rows: 2;
                }
              `}
            >
              <Box>
                <Heading5>Set up on phone</Heading5>
                <List>
                  <ListItem>
                    Open the Samsung Pay app, follow the on-screen prompts and
                    tap install to begin.
                  </ListItem>
                  <ListItem>Sign in to your Samsung account.</ListItem>
                  <ListItem>
                    Take a photo of your card or enter your details manually.
                  </ListItem>
                  <ListItem>Verify your card by sms or phone.</ListItem>
                  <ListItem>
                    Start tapping with Samsung Pay and Latitude Visa or
                    Mastercard.
                  </ListItem>
                </List>
              </Box>
              <Box
                css={`
                  @media (min-width: ${BREAKPOINT.LG}) {
                    grid-area: 2 / 2;
                  }
                `}
              >
                <Heading5>How to use</Heading5>
                <List>
                  <ListItem>Swipe up on your Samsung smartphone.</ListItem>
                  <ListItem>Choose your card.</ListItem>
                  <ListItem>Authorise your payment.</ListItem>
                  <ListItem>
                    Hold your phone close to the payment terminal, pay and away
                    you go.
                  </ListItem>
                </List>
              </Box>
              <Box
                css={`
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    grid-column: 1;
                    grid-row: 1 / 3;
                  }
                `}
              >
                <img
                  css={`
                    max-width: 240px;
                  `}
                  src={imgHowToUse}
                  alt="Phone - Samsung Pay"
                />
              </Box>
              <Box>
                <Heading5>Set up on watch</Heading5>
                <List>
                  <ListItem>
                    Open your Samsung Gear Manager on your smartphone and tap
                    &apos;setting&apos; to select Samsung Pay.
                  </ListItem>
                  <ListItem>Sign in to your Samsung account.</ListItem>
                  <ListItem>
                    Take a photo of your card or enter your details manually.
                  </ListItem>
                  <ListItem>Verify your card by sms or phone.</ListItem>
                  <ListItem>
                    Start tapping with Samsung Pay and Latitude Visa or
                    Mastercard.
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Heading5>How to use</Heading5>
                <List>
                  <ListItem>Hold the top button on your Samsung Gear.</ListItem>
                  <ListItem>Enter your 4-digit PIN.</ListItem>
                  <ListItem>Choose your card.</ListItem>
                  <ListItem>
                    Hold your Samsung Gear close to the payment terminal, pay
                    and away you go.
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Box.Section>
        <Box.Section
          isResponsive
          isBodyCopy
          css={`
            padding: 8px 0;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 40px 0;
            }
          `}
          backgroundColor={COLOR.BLUE_BABY}
        >
          <Box padding="0 15px">
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Just got approved? You don&apos;t have to wait for your card to
              start spending.
            </Heading4>
            <Box marginBottom={MARGIN.M32}>
              <Text align={ALIGN.CENTER}>
                If you have a Latitude Visa or Mastercard you can start using
                your card instantly via Samsung Pay. Here&apos;s how:
              </Text>
            </Box>
            <FramedContainer
              theme={{
                frameColor: COLOR.BLUE_LIGHT_BRIGHT,
                contentBorderColor: 'none'
              }}
            >
              <Box padding={PADDING.P24}>
                <ListNumbered
                  data={[
                    'First, download the&nbsp;<a href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" style="display: contents; text-decoration: underline;">Latitude App</a>&nbsp;and create your Latitude ID.',
                    'Link your Latitude Visa or Mastercard using your account number (you&apos;ll find this in the email we sent to you when you were approved). You&apos;ll receive a verification SMS with a four-digit code to insert.',
                    'Next, go to your Samsung Pay wallet on your phone and insert your name and date of birth. You may receive a verification SMS at this stage.',
                    'Start using your card instantly through Samsung Pay.'
                  ]}
                  viewStyle="compact"
                  counterBgColor={COLOR.BLUE_DEEP}
                  counterVerticalAlign
                  separator="light"
                />
                <div>
                  <hr className="mt-3 mb-4 light-border" />
                  <Text fontSize={FONT_SIZE.SMALL}>
                    It&apos;s important to be aware that you might be limited to
                    transactions under $100 until your card arrives in the post,
                    you activate it and set a PIN.
                  </Text>
                </div>
              </Box>
            </FramedContainer>
          </Box>
        </Box.Section>
        <Box.Section
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 8px 0;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 40px 0;
            }
            .accordion__item-body .accordion__item-body {
              padding-top: 16px !important;
            }
          `}
        >
          <Box padding="0 15px">
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginBottom={MARGIN.M40}
            >
              Frequently asked questions
            </Heading4>
            <AccordionSidebar data={faqs.samsungPay} />
          </Box>
        </Box.Section>
        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                '^ Iris authentication is available on the Samsung Galaxy S9, S9+, Note8, S8 and S8+. Iris authentication may not function in certain circumstances.'
              ],
              importantInformationSectionTwo: [
                '<sup>&gt;</sup> Samsung Pay will only work on compatible Samsung smartphones. Samsung, Samsung Pay, the Samsung logo are trademarks of Samsung Inc.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
}

export default SamsungPay;
